export const environment = {
  production: false,
  baseHref: '/',
  redirectUri: 'https://dev.ess.amgreetings.com/',
  postLogoutRedirectUri: 'https://dev.ess.amgreetings.com/',
	webApi: 'https://api-dev.ess.amgreetings.com',
  googleApi: 'AIzaSyDe_6K2nTUe3Ra-2QHu8tGo9oHk8YZfAD0',
  tablePaginationSizes: [10, 25, 100],
  pageableAllItems: 99999,
  noLoadingSpinnerHeader: 'loading-spinner-disabled',
  httpRequestChunkSize: 100,
  tyVideoUrl: 'https://storageessdev.blob.core.windows.net/ess-media/ty.mp4',
  applicationInsightsConnectionString: 'InstrumentationKey=20880cd2-c045-4f29-b650-d92e4c0a5683;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/'
};
